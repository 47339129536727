import {Injectable} from '@angular/core';
import {EventSender} from "./events/event-sender.interface";
import {event} from "jquery";


declare let window;

@Injectable({
    providedIn: 'root'
})
export class ClarityEventSender implements EventSender {

    constructor() {
    }

    identify(userId: string): void {
        if (!window.clarity) {
            return;
        }
        window.clarity("set", "userId", userId);
    }

    init(userProperties: {}): void {

    }

    log(event: string, data?: any): void {
        if (!window.clarity) {
            return;
        }

        switch (event) {
            case 'desk-script-ai-start':
                window.clarity("set", "ai writer", "open");
                break;
            case'desk-script-ai-input':
                window.clarity("set", "ai writer", `input ${data?.aiFeature || 'aiFeature'}`);
                break;
            case'desk-script-ai-output':
                window.clarity("set", "ai writer", `output ${data?.aiFeature || 'aiFeature'}`);
                break;
            case'new-story-select':
                window.clarity("set", "new script", "script");
                break;
        }
    }

    logPageView(properties: {}): void {
    }

    logRevenue(plan: string, price: number): void {
    }

    clear(): void {
    }

    logSinglePurchases(purchaseQuantity: number): void {
    }

    updateUserProperties(userProperties: {}): void {
    }


}
