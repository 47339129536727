import {ElementRef, ViewChild, Directive, Output, EventEmitter} from '@angular/core';
import {RegistrationData} from "../../../auth/models/registration-data";
import moment from "moment/moment";
import {environment} from "../../../environments/environment";
import {
    DEFAULT_GRANT_TYPE,
    DEFAULT_ORGANIZATION_NAME,
    DEFAULT_PROVIDER,
    LOGIN_PROCESS_FAILURE_URL
} from "../../../auth/constants/strings";
import {AdminRequestsService} from "../../services/admin-requests.service";
import {FirstpromoterService} from "../../services/firstpromoter.service";
import {EventsService} from "../../services/events.service";
import {LoginHelperService} from "../../services/login-helper.service";
import {ErrorParseService} from "../../services/error-parse.service";
import {User} from "../../models/user";
import {ErrorElementService} from "../../services/error-element.service";
import {DialogData} from "../../models/dialog-data";
import {DialogConfig, DialogHelperService} from "../../services/dialog-helper.service";
import {ActivationDialogComponent} from "../../../auth/components/activation-dialog/activation-dialog.component";
import {DeskManagerHelperService} from "../../services/desk-manager-helper.service";
import {PlanHelperService} from "../../services/plan-helper.service";
import {UserHelperService} from "../../services/user-helper.service";
import {OffersHandlerService} from "../../services/offers-handler.service";
import {OrganizationStateService} from "../../services/organization-state.service";
import {Router} from "@angular/router";
import {SocialProviderType} from "../../../auth/models/social-provider-type";
import {RegistrationForm} from "../../../registration/config/registration-form-config";
import {AuthDataAdapterService} from "../../../auth/services/auth-data-adapter.service";
import {NgFirebaseAuthService} from "../../../auth/services/ng-firebase-auth.service";
import {AnonymousUserService} from "../../services/anonymous-user.service";

declare let window: any;

@Directive()
export abstract class BaseRegisterLogicComponent  {

    @ViewChild('error') error: ElementRef = null;
    @Output() onRegisterErrorCallback : EventEmitter<void> = new EventEmitter<void>();

    loginData: any;
    submitting: boolean;
    errorMessage: string;
    hasErrorElement: boolean;
    termsAgreed: boolean;
    registrationSecretQueryParam: string;
    organizationNameQueryParam: string;
    showAgreementStateError: boolean;
    offerKeyQueryParam: string;
    emailQueryParam: string;

    protected constructor(
        protected adminRequestsService: AdminRequestsService,
        protected firstpromoterService: FirstpromoterService,
        protected eventsService: EventsService,
        protected loginHelperService: LoginHelperService,
        protected errorParseService: ErrorParseService,
        protected errorElementService: ErrorElementService,
        protected deskManagerHelperService: DeskManagerHelperService,
        protected planHelperService: PlanHelperService,
        protected userHelperService: UserHelperService,
        protected offersHandlerService: OffersHandlerService,
        protected organizationStateService: OrganizationStateService,
        protected router: Router,
        protected dialogHelperService: DialogHelperService,
        protected authDataAdapterService: AuthDataAdapterService,
        protected firebaseAuthService: NgFirebaseAuthService,
        protected anonymousUserService : AnonymousUserService,
    ) {
        this.loginData = {};
        this.termsAgreed = true;
    }


    registerWithMail = (loginFormValues: RegistrationForm, role? : string) => {
        const {fullName, email, password} = loginFormValues;

        const registrationData: RegistrationData = {
            email,
            fullName,
            organizationName: DEFAULT_ORGANIZATION_NAME,
            auth: {
                password: password,
                provider: DEFAULT_PROVIDER
            }
        };

        if(role) {
            registrationData['industry'] = role;
        }

        this.loginData = {
            username: email,
            password: password,
            grant_type: DEFAULT_GRANT_TYPE,
        };

        this.register(registrationData);
    }

    registerWithProvider = async (provider: SocialProviderType) => {

        try {

            const eventName = provider.includes('facebook') ? 'facebook-button' :
                provider.includes('google') ? 'google-button' :
                    provider.includes('apple') ? 'apple-button' : null

            if(eventName) {
                this.eventsService.log(eventName);
            }

            const providerName = this.authDataAdapterService.adaptProvider(provider);

            const authData = await this.firebaseAuthService.loginWithSocialProvider(provider);

            const registrationData: RegistrationData = {
                organizationName: DEFAULT_ORGANIZATION_NAME,
                auth: {
                    accessToken: authData.accessToken,
                    provider: providerName
                }
            };

            if (this.registrationSecretQueryParam && this.emailQueryParam) {
                registrationData.email = this.emailQueryParam;
            }

            this.loginData = {
                access_token: authData.accessToken,
                grant_type: provider
            };

            this.register(registrationData);

        } catch (error) {
            if (error === "popup_closed_by_user") {
                return;
            }
        }
    }

    register = async (data: RegistrationData) => {
        this.submitting = true;
        this.errorMessage = "";
        this.hasErrorElement = false;

        if (!this.termsAgreed) {
            return;
        }

        if (this.registrationSecretQueryParam) {
            data.registrationSecret = this.registrationSecretQueryParam;
        }

        if (this.organizationNameQueryParam) {
            data.organizationName = this.organizationNameQueryParam;
        }

        this.firstpromoterService.addRefIdIfExists(data)

        try {
            const user = await this.adminRequestsService.registerUser(data);
            const wasAnonymous = await this.anonymousUserService.getAnonymousUser();
            try {
                await this.anonymousUserService.clearAll();
            }catch (e) {

            }

            /**
             * Temp solution to distinguish already-existed users that
             * registered using social media
             */
            const userCreationDate = moment(user.created);
            const now = moment();
            const diff = now.diff(userCreationDate, 'seconds');

            if (diff <= 10 || this.registrationSecretQueryParam) {
                this.eventsService.log(this.eventsService.events.user.register, {
                    provider: data.auth.provider,
                    email: user.email,
                    fullName: user.fullName,
                    signupType: this.loginHelperService.checkSignUpType(data)
                });
            }


            const returnPage = this.loginHelperService.getReturnPage();

            if (this.hasNonDefaultReturnPage(returnPage) || data.registrationSecret || this.isActiveUser(user)) {
                const successUrl = returnPage || environment.globals.firstAppPage;
                await this.processLogin(LOGIN_PROCESS_FAILURE_URL);
                !!wasAnonymous && this.dialogHelperService.closeAll();
                !!wasAnonymous && this.anonymousUserService.broadcastNotAnonymous();
                this.moveToBoards(successUrl);
            } else {
                await this.processLogin(LOGIN_PROCESS_FAILURE_URL);
                !!wasAnonymous && this.dialogHelperService.closeAll();
                !!wasAnonymous && this.anonymousUserService.broadcastNotAnonymous();
                !wasAnonymous && this.openActivationDialog(!!wasAnonymous);
            }

        } catch (error) {
            const parsedError = this.errorParseService.parseRegistrationError(error);
            if (typeof parsedError === 'string') {
                if(parsedError === 'open-existing-account-dialog') {
                    this.onRegisterErrorCallback.emit();
                }else{
                    this.errorMessage = parsedError;
                }
            } else {
                this.hasErrorElement = true;
                this.errorElementService.appendError(this.error, parsedError);
            }

        } finally {
            this.submitting = false;
        }
    }

    processLogin = async (failureUrl: string) => {
        try {
            this.submitting = true;

            this.planHelperService.clear();
            this.userHelperService.clear();
            this.deskManagerHelperService.clear();

            await this.loginHelperService.login(this.loginData);

            this.deskManagerHelperService.initializeWorkspace();
            await this.organizationStateService.init();

            if (this.offerKeyQueryParam) {
                return this.offersHandlerService.handleStripeSessionWithOffer(this.offerKeyQueryParam);
            }


            const plan = await this.planHelperService.getOrganizationPlansDetails();
            this.planHelperService.broadcastOrganizationPlan(plan);
            await this.eventsService.updateRegister();

            window.$crisp.push(["config", "hide:on:load", false]);

        } catch (error) {
            failureUrl ? this.router.navigate([failureUrl]) : null;
        } finally {
            this.submitting = false;
        }
    }

    onTermsAgreed = (agreed: boolean) => {
        this.termsAgreed = agreed;
        this.showAgreementStateError = !agreed;
    }


    hasNonDefaultReturnPage = (returnPage: string): boolean => {
        return (
            returnPage !== null &&
            !returnPage.includes(environment.globals.firstAppPage)
        );
    }

    isActiveUser = (user: User) => {
        return user && user.status === 300;
    };


    openActivationDialog = (anonymous = false) => {
        const data = new DialogData();
        data.onSubmit = () => this.onActivationDialogSubmit(anonymous);
        const config = new DialogConfig();
        config.disableClose = true;
        config.panelClass = 'activation-dialog-content';
        this.dialogHelperService.open(data, ActivationDialogComponent, config);
    }


    onActivationDialogSubmit = (anonymous = false) => {
        this.dialogHelperService.close();
        !anonymous && this.moveToBoards();
    }

    moveToBoards = (successUrl: string = environment.globals.firstAppPage) => {
        this.router.navigate([successUrl]);
    }


    loginWithSSO = async (organizationId: string, SAMLResponse : string, RelayState?: string) => {
        this.submitting = true;
        this.errorMessage = "";
        this.hasErrorElement = false;

        try {
            this.planHelperService.clear()
            this.userHelperService.clearUser();
            this.deskManagerHelperService.clear();
            await this.adminRequestsService.loginWithSSO(
                organizationId,
                SAMLResponse,
                RelayState)
            this.deskManagerHelperService.initializeWorkspace();
            await this.eventsService.init();
            await this.organizationStateService.init();
            // this.eventsService.log(this.eventsService.events.user.login, {
            //     email: data.username,
            //     provider: data.grant_type
            // });
            // this.websocketService.connect();

            let url = this.loginHelperService.getReturnPage();

            if (url && url !== '/') {
                this.router.navigate([url]);
            } else {
                this.router.navigate([environment.globals.firstAppPage]);
            }

        } catch (error) {
            throw error;
        } finally {
            this.submitting = false;
        }
    }

}
