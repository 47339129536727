import { Injectable } from '@angular/core';
import { AuthData } from '../models/auth-data';
import { SocialProviderType } from '../models/social-provider-type';

@Injectable({providedIn: "root"})
export class AuthDataAdapterService {

    constructor() { 

    }

    adaptSocialLoginData = (authData: AuthData, provider: SocialProviderType): AuthData => {
        if (provider === "apple") {
            authData["identity_token"] = authData.idToken;
            authData["grant_type"] = "firebase";
            delete authData.authorizationCode;

        } else {
            authData["access_token"] = authData.accessToken;
            authData["grant_type"] = authData.provider;
            if (authData.authorizationCode) {
                authData["authorization_code"] = authData.authorizationCode;
                delete authData.authorizationCode;
            }
        }
        delete authData.idToken;
        delete authData.accessToken;
        delete authData.provider;

        return authData;
    }

    adaptProvider = (provider: string): string => {
        return provider === 'google_plus' ? 'googlePlus' : provider;
    }

}