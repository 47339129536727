import {EventSender} from "../event-sender.interface";
import {environment} from "../../../../environments/environment";
import {AngularFireAnalytics} from "@angular/fire/compat/analytics";
import {event} from "jquery";


export class FirebaseEventSender implements EventSender {

    constructor(private angularFireAnalytics: AngularFireAnalytics) {
    }

    init(userProperties: {}): void {
    }

    identify(userId: string): void {
    }

    log(event: string, data?: any): void {
        if (environment.production) {
            this.angularFireAnalytics.logEvent(`${event.replace(/-/g, '_')}`, data)

            switch (event) {
                case "register":
                    this.angularFireAnalytics.logEvent('sign_up', {})
                    break;
            }
        }
    }

    logRevenue(plan: string, price: number): void {
        if (environment.production) {
            // noinspection JSUnresolvedFunction
            const data = {
                currency: 'USD',
                value: price,
                items: [{
                    item_name: plan,
                    price: price
                }]
            };
            this.angularFireAnalytics.logEvent('purchase', data)
        }
    }

    logPageView(properties: {} = {}): void {
    }

    clear(): void {
    }

    logSinglePurchases(purchaseQuantity: number): void {
    }

    updateUserProperties(userProperties: {}): void {
    }

}