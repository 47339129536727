<div class="dynamic-form-component">
    <form
            [formGroup]="dynamicForm"
            (keyup.enter)="onEnterKeyPress($event)">
        <div
                *ngFor="let controlConfig of controlsConfig; index as i"
                class="control-container">
            <text-input
                    [inputFormControl]="formControls[controlConfig?.name]"
                    [label]="controlConfig?.label"
                    [placeholder]="controlConfig?.placeholder"
                    [type]="controlConfig?.type"
                    [showClearButton]="false"
                    [tabIndex]="i + 1">
            </text-input>
        </div>

        <ng-container
                [ngTemplateOutlet]="extraContentRef">
        </ng-container>

        <div class="submit-buttons">
            <div
                    *ngFor="let button of submitActionButtons; index as i"
                    class="button-container">
                <action-button
                        [text]="button.text"
                        [style]="button.style"
                        [disabled]="disabled || (disableInvalid && dynamicForm.invalid)"
                        (onClick)="submit(button)">
                </action-button>
            </div>
        </div>
    </form>
</div>
