<div class="image"
     [style.opacity]="opacity"
     [style.width.px]="width !== undefined ? width: 'auto'"
     [style.height.px]="height !== undefined ? height: 'auto'"
     [style.top.%]="top !== undefined ? top: 'auto'"
     [style.left.%]="left !== undefined ? left: 'auto'"
     [style.right.%]="right !== undefined ? right: 'auto'"
     [style.bottom.%]="bottom !== undefined ? bottom: 'auto'"
     [style.z-index]="zIndex !== undefined ? zIndex: 0">
    <img [src]="src" alt="image">
</div>
