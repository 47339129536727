import {Injectable} from '@angular/core';
import {DialogConfig, DialogHelperService} from "../shared/services/dialog-helper.service";
import {
    PortableRegisterComponent,
    PortableRegisterDialogData
} from "./components/portable-register/portable-register.component";
import {Story} from "../shared/models/story";
import {
    ExistingUserDialogData,
    ExistingUserDialogComponent
} from "../shared/components/existing-user-dialog/existing-user-dialog.component";
import {PortableRegisterSubmitAction} from "./constants/portable-register-actions";

@Injectable({
    providedIn: 'root'
})
export class PortableRegisterService {

    constructor(private dialogHelperService: DialogHelperService) {
    }

    openPortableRegisterDialog = (lock: boolean = false) => {
        const data = new PortableRegisterDialogData();
        data.onClose = this.dialogHelperService.close;
        data.onSubmit = (submitAction: PortableRegisterSubmitAction) => this.onSubmitType(submitAction);
        const config = new DialogConfig();
        config.disableClose = lock;
        config.panelClass = 'portable-register-dialog-content';
        this.dialogHelperService.open(data, PortableRegisterComponent, config);
    }

    onSubmitType = (submitAction: PortableRegisterSubmitAction) => {
        this.dialogHelperService.close();
        switch (submitAction) {
            case PortableRegisterSubmitAction.existingUser:
                const data = new ExistingUserDialogData()
                data.onClose = this.dialogHelperService.close;
                data.onSubmit = this.dialogHelperService.close;
                const config = new DialogConfig()
                config.panelClass = 'existing-user-dialog-container'
                this.dialogHelperService.open(data, ExistingUserDialogComponent, config);
                break;
        }
    }

}
