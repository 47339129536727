<div class="terms-agreement-container">
    <div 
        class="checkbox-container"
        (click)="toggleAgreementState()">
        <div 
            [hidden]="!termsAgreed"
            class="checkmark">
        </div>
    </div>
    <div class="text-container">
        <span class="title">I agree to the</span>
        <a href="https://bigvu.tv/terms-of-service.html" target="_blank">terms of use</a>
        <span class="title">and</span>
        <a href="https://bigvu.tv/privacy-policy.html" target="_blank">privacy policy</a>
    </div>
</div>