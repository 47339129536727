import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-absolute-circle',
    templateUrl: './absolute-circle.component.html',
    styleUrls: ['./absolute-circle.component.css'],
    standalone: true
})
export class AbsoluteCircleComponent implements OnInit {

  @Input() size: number;
  @Input() top?: number;
  @Input() bottom?: number;
  @Input() right?: number;
  @Input() left?: number;
  @Input() zIndex?: number;
  @Input() opacity: number = 0.1;
  @Input() color : string = 'white';



  constructor() { }

  ngOnInit(): void {
  }

}
