import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'terms-and-conditions-disclaimer',
    templateUrl: './terms-and-conditions-disclaimer.component.html',
    styleUrls: ['./terms-and-conditions-disclaimer.component.css'],
    standalone: true
})
export class TermsAndConditionsDisclaimerComponent {

    @Output() onTermsAgreed: EventEmitter<boolean>;

    termsAgreed: boolean;

    constructor() {
        this.onTermsAgreed = new EventEmitter<boolean>();
        this.termsAgreed = true;
    }

    toggleAgreementState = () => {
        this.termsAgreed = !this.termsAgreed;
        this.onTermsAgreed.emit(this.termsAgreed);
    }
}
