import {Component, Inject, OnInit} from '@angular/core';
import {DialogComponent} from "../../../shared/components/dialog/dialog.component";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {DialogData} from "../../../shared/models/dialog-data";
import { RegistrationComponent } from '../registration.component';
import {PortableRegisterSubmitAction} from "../../constants/portable-register-actions";

export class PortableRegisterDialogData extends DialogData<PortableRegisterSubmitAction> {

}

@Component({
    selector: 'app-portable-register',
    templateUrl: './portable-register.component.html',
    styleUrls: ['./portable-register.component.css'],
    standalone: true,
    imports: [RegistrationComponent]
})
export class PortableRegisterComponent extends DialogComponent<PortableRegisterSubmitAction> implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data : PortableRegisterDialogData) {
    super(data);
  }

  ngOnInit(): void {

  }

  openExistUserDialog = () => {
    this.submit(PortableRegisterSubmitAction.existingUser)
  }

}
