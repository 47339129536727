import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'action-button',
    templateUrl: './action-button.component.html',
    styleUrls: ['./action-button.component.css'],
    standalone: true,
    imports: [NgClass, NgIf]
})
export class ActionButtonComponent implements OnInit {

    @Input() text: string;
    @Input() style: string;
    @Input() disabled: boolean = false;
    @Input() sideIconUrl: string;

    @Output() onClick: EventEmitter<void>;

    constructor() {
        this.onClick = new EventEmitter<void>();
    }

    ngOnInit() {

    }

    click = () => {
        if (this.onClick) {
            this.onClick.emit();
        }
    }

}
