import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-absolute-image',
    templateUrl: './absolute-image.component.html',
    styleUrls: ['./absolute-image.component.css'],
    standalone: true
})
export class AbsoluteImageComponent implements OnInit {

    @Input() width?: number;
    @Input() height?: number;
    @Input() src: string;
    @Input() top?: number;
    @Input() bottom?: number;
    @Input() right?: number;
    @Input() left?: number;
    @Input() zIndex?: number;
    @Input() opacity: number = 1

    constructor() {
    }

    ngOnInit(): void {
    }

}
