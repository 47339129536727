import {Inject, Injectable, OnDestroy, PLATFORM_ID} from '@angular/core';
import {LoginHelperService} from './login-helper.service';
import {Location} from '@angular/common';
import {AngularFireAnalytics} from "@angular/fire/compat/analytics";
import {SessionService} from './session.service';
import {DeskManagerHelperService} from './desk-manager-helper.service';
import {EventSender} from "./events/event-sender.interface";
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs';
import {MixpanelEventSender} from "./events/senders/mixpanel-event-sender";
import {GtagEventSender} from "./events/senders/gtag-event-sender";
import {FacebookEventSender} from "./events/senders/facebook-event-sender";
import {FirebaseEventSender} from "./events/senders/firebase-event-sender";
import {PlanHelperService} from "./plan-helper.service";
import {UserHelperService} from "./user-helper.service";
import {SentryEventSender} from "./events/senders/sentry-event-sender";
import {NgCookieService} from "./ng-cookie.service";
import {ClarityEventSender} from "./clarity-event-sender.service";
import EventNames from "./events/event-names";
import {AbTestManagerService} from "./ab-test-manager.service";
import {isPlatformBrowser} from "@angular/common";
import {getLanguages, getNameByCode} from "../configs/language-country-currency.config";
import {User} from "../models/user";
import {Plan} from "../models/plan";

@Injectable({
    providedIn: 'root'
})
export class EventsService implements OnDestroy {

    detailsPromise: Promise<any>;
    events: any;
    private eventSenders: EventSender[] = new Array<EventSender>();
    private superProperties: {} = {};

    constructor(
        private planHelperService: PlanHelperService,
        private userHelperService: UserHelperService,
        private loginHelperService: LoginHelperService,
        private location: Location,
        angularFireAnalytics: AngularFireAnalytics,
        private sessionService: SessionService,
        private workspaceHelperService: DeskManagerHelperService,
        private router: Router,
        @Inject(PLATFORM_ID) private platformId: object,
        cookieService: NgCookieService,
        abTestManagerService: AbTestManagerService,
    ) {
        this.setEvents();
        if (isPlatformBrowser(this.platformId)) {
            this.eventSenders.push(new MixpanelEventSender(this.sessionService.isSudoSession(), cookieService, abTestManagerService, platformId))
            this.eventSenders.push(new GtagEventSender())
            this.eventSenders.push(new FacebookEventSender())
            this.eventSenders.push(new FirebaseEventSender(angularFireAnalytics))
            this.eventSenders.push(new SentryEventSender());
            this.eventSenders.push(new ClarityEventSender());
            this.initPageViewEvent();
            this.init();
        }
    }


    private initPageViewEvent() {
        this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd)
            )
            .subscribe((event: NavigationEnd) => {
                let properties = {
                    url: event.url,
                    host: location.origin,
                    path: this.extractPath(location.pathname)
                };
                new URL(window.location.href).searchParams.forEach(function (val, key) {
                    if (key.startsWith("utm_"))
                        properties[key] = val
                });
                properties = Object.assign({}, this.superProperties, properties);
                this.perform(eventSender => eventSender.logPageView(properties))
            });
    }

    extractPath(path) {
        try {
            const segments = path.split('/');
            const filteredSegments = segments.filter(segment => !/^[0-9a-fA-F]{24}$/.test(segment));
            return filteredSegments.join('/');
        } catch (e) {
            return path;
        }
    }

    init = () => {
        if (this.loginHelperService.isUserLoggedIn() && !this.onRefPage() && window.location.pathname.split('/')[1] != "standalone") {
            this.detailsPromise = Promise.all(this.getDetailsPromises())
                .then(async (data) => {
                    // noinspection JSUnresolvedFunction
                    let userProperties = this.getNotEmptyData({
                        "$email": data[0].email,
                        "$created": data[0].created,
                        "$last_login": new Date(),
                        "lastVisit": data[0].lastVisit,
                        "$name": data[0].fullName,
                        "$phone": data[0].phone,
                        "company": data[0].company,
                        "type": data[0].type,
                        "organizationId": data[0].organizationId,
                        "planAuthority": data[1].authority,
                        "currentPlan": data[1].name,
                        "role": data[0].industry,
                        "currentPlatform": "Web",
                        "language": getNameByCode(getLanguages(), data[0].lang, "English")
                    });

                    this.superProperties['planName'] = data[1].name;
                    this.superProperties['userId'] = data[0].userId;
                    this.superProperties['platform'] = 'Web';

                    const userOrganizationId = data[0].organizationId;

                    const currentOrganization = await this.workspaceHelperService.getOrganizationData();
                    currentOrganization && Object.assign(userProperties, {
                        currentOrganizationId: currentOrganization.organizationId,
                        guestAction: currentOrganization.organizationId !== userOrganizationId || undefined
                    });

                    this.perform(eventSender => eventSender.init(userProperties))

                    this.planHelperService.broadcastOrganizationPlan(data[1]);
                    return data;
                });
            return this.detailsPromise;
        }
        this.detailsPromise = Promise.resolve()
        return this.detailsPromise;
    }

    getDetailsPromises = (reload: boolean = false): [Promise<User>, Promise<Plan>] => {
        return [
            this.identify(reload),
            this.planHelperService.getOrganizationPlansDetails(reload)
        ];
    }

    identify = (reload: boolean = false) => {
        return this.userHelperService.getUserDetails(reload)
            .then((data: any) => {
                // noinspection JSUnresolvedFunction
                this.perform(eventSender => eventSender.identify(data.userId));
                return data;
            }, () => {
            });
    }

    updateRegister = async (attr?: string, value?: string) => {
        const userDetails = await Promise.all(this.getDetailsPromises(this.detailsPromise === undefined));
        const eventOptions = {
            "userId": userDetails[0].userId,
            "planName": userDetails[1].name,
            "platform": 'Web'
        };

        if (attr && value) {
            eventOptions[attr] = value;
        }

        // noinspection JSUnresolvedFunction
        this.superProperties = this.getNotEmptyData(eventOptions);
    }

    getNotEmptyData = (data: any) => {
        let _data = {};
        for (let key in data) {
            if (data[key] !== "" && data[key] !== null && data[key] !== undefined) {
                _data[key] = data[key];
            }
        }
        return _data;
    }

    log = (event: string, data?: any) => {
        if (this.sessionService.isSudoSession()) {
            return;
        }

        // noinspection JSUnresolvedFunction
        const nonEmptyData = this.getNotEmptyData(data);
        const properties = Object.assign({}, this.superProperties, nonEmptyData)
        this.perform(eventSender => eventSender.log(event, properties))

    }

    logRevenue = (plan: string, price: number) => {
        this.perform(eventSender => eventSender.logRevenue(plan, price))
    }

    logSinglePurchases = (purchaseQuantity: number) => {
        this.perform(eventSender => eventSender.logSinglePurchases(purchaseQuantity))
    }

    updateUserProperties = (properties: { [key: string]: any }) => {
        this.perform(eventSender => eventSender.updateUserProperties(properties))
    }

    clear = (): void => {
        this.detailsPromise = undefined;
    }

    onRefPage = (): boolean => {
        return this.location.path() === '/ref';
    }

    private perform(f: (eventSender: EventSender) => void) {
        this.eventSenders.forEach(f)
    }

    /**
     * The available events in the app, categorized by feature and ordered alphabetically
     * NOTE: If you add a new event PLEASE keep in mind the order
     */
    setEvents = () => {
        this.events = EventNames
    }

    ngOnDestroy() {
        this.eventSenders.forEach(e => e.clear())
    }


}
