import { ValidatorFn } from "@angular/forms";
import { FormControlType } from "./form-control-type";

export class FormControlConfig {
    constructor(
        public name: string,
        public type: FormControlType,
        public placeholder?: string,
        public label?: string,
        public validators?: ValidatorFn[],
        public value?: any,
        public disabled: boolean = false,
        public autocomplete  = 'off'
    ) {

    }

    clone = (): FormControlConfig => {
        return new FormControlConfig(
            this.name,
            this.type,
            this.placeholder,
            this.label,
            [...this.validators],
            this.value,
            this.disabled,
            this.autocomplete
        );
    }
    
    addValidator = (validator: ValidatorFn): FormControlConfig => {
        if (this.validators && this.validators.length > 0) {
            this.validators.push(validator);
        } else {
            this.validators = [validator];
        }
        return this;
    }

    setValue = (value: any): FormControlConfig => {
        this.value = value;
        return this;
    }

    setDisabled = (disabled: boolean): FormControlConfig => {
        this.disabled = disabled;
        return this;
    }
}
