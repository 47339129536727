export default {
    "askVideo": {
        "askVideoStart": "ask-video-start",
        "inlineVideoInteract": "inline-video-interact",
        "inlineVideoFinish": "inline-video-finish"
    },
    "aiMusic": {
        "brandMusicStart": "ai-music-start",
        "brandMusicGenerate": "ai-music-generate",
        "brandMusicDismiss": "ai-music-dismiss",
        "brandMusicOutput": "ai-music-output",
        "brandMusicRate": "ai-music-rate",
        "brandMusicDelete": "ai-music-delete",
        "brandMusicRetry": "ai-music-retry",
        "brandMusicRegenerate": "ai-music-brand-regenerate",
        "brandMusicSave": "ai-music-brand-save",
        "aiMusicTagClick" : "ai-music-tag-click",

    },
    "aiAvatar": {
        "aiAvatarCreateStart": "ai-avatar-create-start",
        "aiAvatarCreateButtonClick": "ai-avatar-create-button-click",
        "aiAvatarCreatePageShow": "ai-avatar-create-page-show",
        "aiAvatarStart": "ai-avatar-start",
        "aiAvatarGenerate": "ai-avatar-generate",
        "aiAvatarGenerateDialogClick": "ai-avatar-generate-dialog-click",
        "aiAvatarVideoGenerateError" : "ai-avatar-video-generate-error"
    },
    "audiogram": {
        "audiogramStart": "voice-generator-start",
        "audiogramNext": "voice-generator-next",
        "voiceGeneratorDismiss": "voice-generator-dismiss",
        "audiogramCreate": "voice-generator-create",
        "voiceGeneratorPreviewPlay": "voice-generator-preview-play",
        "voiceGeneratorPreviewSelect": "voice-generator-preview-select",
        "voiceGeneratorAudiogramEdit": "voice-generator-audiogram-edit",
        "audiogramVideoPlay": "voice-generator-video-play",
        "audiogramVideoView": "voice-generator-video-view",
        "audiogramFilterApply": "voice-generator-filter-apply",
        "audiogramCloneAccept": "voice-generator-clone-accept",
        "audiogramCloneError": "voice-generator-clone-error",
        "voiceGeneratorCloneRecord": "voice-generator-clone-record",
        "audiogramCloneStart": "voice-generator-clone-start",
        "audiogramCloneFinish": "voice-generator-clone-finish",
        "voiceGeneratorVoiceClick": "voice-generator-voice-click",
        "voiceGeneratorVoiceDelete": "voice-generator-voice-delete",
        "voiceGeneratorAssetAddStart": "voice-generator-asset-add-start",
        "voiceGeneratorAssetAdd": "voice-generator-asset-add",
        "voiceGeneratorCloneNext": "voice-generator-clone-next",
        "voiceGeneratorCloneOpen": "voice-generator-clone-open",
        "voiceGeneratorCloneDismiss": "voice-generator-clone-dismiss",
        "voiceGeneratorAudiogramAssetClick" : "voice-generator-audiogram-asset-click",

    },
    "aiPopup": {
        "aiPopupSelect": 'ai-popup-select',
        "aiPopupScreen": 'ai-popup-screen',
        "aiPopupDismiss": 'ai-popup-dismiss'
    },
    "aiBrandTone": {
        'aiBrandToneEditStart': "ai-brand-tone-edit-start",
        'aiBrandToneGenerate': 'ai-brand-tone-generate',
        'aiBrandToneGenerateAddScript': 'ai-brand-tone-generate-add-script',
        'aiBrandToneGenerateDescribe': 'ai-brand-tone-generate-describe',
        'aiBrandToneGenerateStart': 'ai-brand-tone-generate-start',
        'aiBrandToneOutput': 'ai-brand-tone-output',
        'aiBrandToneSave': 'ai-brand-tone-save'

    },
    "applyTheme": {
        "cancel": "apply-theme-quit",
        "generate": "apply-theme",
        "pause": "apply-theme-pause",
        "play": "apply-theme-play"
    },
    "partnership": {
        "page": "partnership-landing-page",
        "congratulations": "partnership-congratulations",
        "gotoAccountClick": "partnership-goto-account-click"
    },
    "assets": {
        "delete": "asset-delete",
        "screenClick": "asset-screen-click",
        "importVideo": "import-video",
        "importVideoError": "import-video-error",
        "upload": "upload-asset-start",
        "uploadComplete": "upload-asset-end",
        "uploadError": "upload-asset-failed",
        "filterApply": "filter-apply",
        "assetFolderMenuOpen": "asset-folder-menu-open",
        "assetFolderMenuClick": "asset-folder-menu-click",
        "assetNewFolderCreated": "asset-new-folder",
        "assetNewFolderStart": "asset-new-folder-start",
        "assetFolderDelete": "asset-folder-delete",
        "assetFolderIndexForBroll": "asset-folder-index",
        "assetFolderRename": "asset-folder-rename",
        "assetFolderDuplicat": "asset-folder-duplicate",
        "assetFolderMoveToWorkspace": "asset-folder-move",
        "assetFolderSelect": "asset-folder-enter",
    },
    "assetInfo": {
        "open": "asset-info-screen",
        "click": "asset-info-click",
        "moveFolderApply": "asset-info-move-folder",
        "changeSubType": "asset-info-type-change",
        "assetInfoSave": 'asset-info-save'
    },
    "affiliate": {
        "affiliateRequest": 'affiliate-request'
    },
    "boards": {
        "addToSharedBin": "boards-add-to-shared-bin",
        "createPage": "boards-create-page",
        "delete": "delete-story",
        "download": "boards-download",
        "duplicate": "boards-duplicate",
        "edit": "enter-story",
        "embed": "video-copy-link",
        "export": "boards-export",
        "videoPreview": "boards-video-preview",
        "newStorySelect": "new-story-select",
        "importVideoActionDialog": "import-video-action-dialog",
        "importVideoActionDialogDismiss": "import-video-action-dialog-dismiss",
        "importVideoActionDialogClick": "import-video-action-dialog-click",
        "addToSequence": "boards-sequence-add",
        "addToCollection": "boards-collection-add",
        "deskBannerClick": "desk-banner-click"
    },
    "billing": {
        "billingPlanChange": "billing-plan-change",
        "billingPlanManage": "billing-plan-manage",
        "billingInvoiceView": "billing-invoice-view",
        "billingInvoiceCancelStart": "billing-cancel-start",
        "billingInvoiceCancelWarningClick": "billing-cancel-warning-click",
        "billingInvoiceCancelReasonClick": "billing-cancel-reason-click",
        'billingInvoiceSave': 'billing-invoice-save',
        'billingMethodUpdate': 'billing-method-update'

    },
    "channelBranding": {
        "addLogo": "channel-branding-add-logo",
        "saveSlideTemplate": "channel-branding-save-slide-template",
        "saveTextTemplate": "channel-branding-save-text-template"
    },
    "background": {
        "backgroundScreenClick": "background-screen-click",
        "backgroundAssetAdd": "background-asset-add",
        "backgroundApply": "background-apply",
        "backgroundAiGenerate": 'background-ai-generate',
        "backgroundAIGenerateClick": 'background-ai-generate-click',
        "backgroundAIImageSelect": 'background-ai-image-select',
        "backgroundAIImageUpload": 'background-ai-image-upload'
    },
    "collaborate": {
        "addMemberInviteStart": "member-add-start",
        "memberInviteFinish": "member-add",
        "memberCountExceed": "member-add-exceed",
        "memberCountExceedUpgrade": "member-add-exceed-upgrade",
        "deleteMemberClick": "member-delete-start",
        "deleteMember": "member-delete",
        "addWorkspaceClick": "workspace-add-start",
        "addWorkspace": "workspace-add",
        "editWorkspaceClick": "workspace-edit-start",
        "editWorkspace": "workspace-edit",
        "editMemberClick": "member-edit-start",
        "editMember": "member-edit",
        "renameWorkspace": "workspace-rename",
        "changeWorkspaceLogoClick": "workspace-edit-logo-start",
        "changeWorkspaceLogo": "workspace-edit-logo",
        "copyMembersClick": "workspace-copy-members-start",
        "copyMembers": "workspace-copy-members",
        "deleteWorkspaceClick": "workspace-delete-start",
        "deleteWorkspace": "workspace-delete",
        "copyMemberEmail": "member-copy-email",
        "memberDismissClick": "member-remove-start",
        "memberDismiss": "member-remove",
        "organizationRenameClick": "team-rename-start",
        "organizationRename": "team-rename",
        "learnMoreClick": "team-learn-more",
        "guestAddStart": "guest-add-start",
        "guestAddFinish": "guest-add",
        "guestEdit": "guest-edit",
        "guestDeleteStart": "guest-delete-start",
        "guestDelete": "guest-delete",
        "guestAddError": "guest-add-error",
        "workspaceChange": "workspace-change"
    },
    "collections": {
        "addInteraction": "collection-add-interaction",
        "addMedia": "collection-add-media",
        "addMember": "collection-add-member",
        "changeRole": "collection-change-role",
        "new": "collection-new",
        "publish": "collection-publish",
        "removeInteraction": "collection-remove-interaction",
        "removeMedia": "collection-remove-media",
        "settings": "collection-settings",
        "copyEmbedLink": "collection-copy-embed",
        "copyPageLink": "collection-copy-page",
    },
    "collectionsOnboarding": {
        "conclusion": "collection-onboard-finish",
        "contactInfo": "collection-social-screen",
        "contactInfoSelect": "collection-social-select",
        "dismiss": "collection-onboard-dismiss",
        "goal": "collection-goal-select",
        "industry": "collection-job-screen",
        "industrySelect": "collection-job-select",
        "jumpIn": "collection-jump-in",
        "start": "collection-onboard-start",
        "uploadEnd": "collection-upload-end",
        "uploadSelect": "collection-upload-button",
        "uploadStart": "collection-upload-start",
        "video": "collection-video-screen",
        "videoSelect": "collection-video-choice"
    },
    "composer": {
        /* crash report */
        "reportCrash": "composer-load-crash",
        "sendCrashReport": "composer-send-crash-report",
        /* segment editing */
        "segmentMenuOpen": "maker-segment-options", // user open segment options menu
        "segmentMenuSelect": "maker-segment-options-select", // user select option from segment menu
        "segmentSplitSave": "maker-segment-split-save", // user submit split a segment dialog
        "segmentDurationSave": "maker-segment-duration-save", // user submit modify duration a segment dialog
        "addSegmentOverlay": "maker-segment-overlay", // user overlays segment with media
        "removeSegmentOverlay": "maker-segment-overlay-remove", // user removes media from segment
        "segmentCaptionChange": "maker-segment-caption-save", // user modified segment text
        "segmentCaptionTooltip": "maker-segment-caption-highlight", // user pressed on segment text tooltip
        /* layout */
        "layoutChange": "maker-layout-change", // user changed slide layout
        /* theme */
        "themeChange": "maker-theme-change", // user changes captions theme template
        "applyTheme": "apply-theme", // user presses on "create" on an editing process.
        "openPaletteEditor": "maker-theme-color-custom-open", // user clicked on a custom color to edit it
        "closePaletteEditor": "maker-theme-color-custom-edit", // user dismisses the color picker menu
        "selectPalette": "maker-theme-color-click", // user selects theme variation or custom variation
        /* fullscreen */
        "enterFullScreen": "maker-full-screen", // user presses on full screen button
        "exitFullScreen": "maker-full-screen-exit", // user exits full screen mode
        /* undo - redo */
        "undoAction": "maker-undo", // user undoes an action
        "redoAction": "maker-redo", // user redoes an action
        /* story actions */
        "openStory": "apply-theme-start", // user enters Video Maker from the project screen by pressing “edit” in the menu options (was maker-open)
        "savePopup": "maker-save-screen", // when save pop-up shows
        "saveStory": "maker-save", // when save pop-up shows
        "closeStory": "maker-close", // user exit the maker screen
        "generateStory": "maker-create", // user presses on the Create button
        "deleteStory": "maker-delete", // user deleted a story
        "loadingEnded": "maker-ready", // sent when the loading ends and the editor is presented to the user
        /* preview player */
        "play": "maker-preview", // user previews the video in the video maker
        /* zoom */
        "zoomAdd": "maker-zoom-add", // user add zoom to slide
        "zoomChange": "maker-zoom-change", // user change the zoom
        "zoomRemove": "maker-zoom-remove", // user remove the zoom
        /* auto zoom */
        "autoZoomOpenModal": "maker-ai-zoom-start", // user clicked on the zoom ai feature in video maker
        "autoZoomSet": "maker-ai-zoom-choose", // user chose an option in the zoom ai feature dialog
        "autoZoomExecuted": "maker-ai-zoom-output", // loading finished, user can now enjoy his automatically zoom effect video
        /* logo */
        "logoStart": "maker-logo-start", // user starts a logo addition process
        "logoCancel": "maker-logo-cancel", // user cancels logo process
        "logoSave": "maker-logo-save", // user saves logo to editing process
        "logoApplyAll": "maker-logo-scenes-apply", // user presses on Yes to apply to all scenes
        /* cropping */
        "enterCrop": "maker-crop-start", // user starts a crop process
        "cancelCrop": "maker-crop-cancel", // user cancels crop
        "saveCrop": "maker-crop-saved", // user saves crop
        "headFound": "maker-crop-head", // a head location found in the thumbnail
        /* intro */
        "introStart": "maker-intro-start", // user starts the intro process
        "introCancel": "maker-intro-cancel", // user cancels process
        "introSave": "maker-intro-save", // user saves intro
        "introRemove": "maker-intro-remove", // user removes intro
        /* outro */
        "outroStart": "maker-outro-start", // user starts the outro process
        "outroCancel": "maker-outro-cancel", // user cancels process
        "outroSave": "maker-outro-save", // user saves outro
        "outroRemove": "maker-outro-remove", // user removes outro
        /* business details */
        "bizEdit": "maker-biz-edit", // user goes to edit business details from inside the editor
        /* asset filter */
        "assetFilterStart": "maker-asset-filter-start", // user opens asset filter dialog
        "assetFilterApply": "maker-asset-filter-apply", // user apply the asset filter from the dialog
        /* studio */
        "makerStudioStart": "maker-studio-start", // user opened the studio
        "makerStudioEnd": "maker-studio-end", // user closed the studio
        "makerStudioUndo": "maker-studio-undo", // user clicked on undo
        "makerStudioRedo": "maker-studio-redo", // user clicked on redo
        "makerStudioTransformStart": "maker-studio-transform-start", // user started to move an element
        "makerStudioTransformEnd": "maker-studio-transform-end", // user finished to move an element
        "makerStudioOverrideDialog": "maker-studio-override-dialog", // override transform to all dialog opened
        "makerStudioOverrideDialogClick": "maker-studio-override-dialog-click", // override transform to all dialog option selected
    },
    "coupons": {
        "redeem": "coupon-redeem",
    },
    "courses": {
        "courseClick": "project-course-click",
        "videoPlay": "course-video-play",
        "videoRepeat": "course-video-repeat",
        "videoAchieve": "course-video-achieve",
        "videoNext": "course-video-next",
        "listClick": "course-list-click",
        "courseVideoExit": 'course-video-view',
        "exit": "course-exit",
        "expertClick": "course-expert-click",
        "finish": "course-finish",
        "certificateShown": "course-congratulations",
        "certificateClick": "course-certificate-click",
        "certificateExit": "course-certificate-exit",
    },
    "deleteStory": {
        "cancel": "delete-story-cancel",
        "confirm": "delete-story-confirm"
    },
    "deleteTake": {
        "cancel": "delete-take-cancel",
        "confirm": "delete-take-confirm"
    },
    "domain": {
        "verifyStart": "domain-verify-start",
        "verifyRepeat": "domain-verify-repeat",
        "deleteClick": "domain-delete-start",
        "delete": "domain-delete",
        "approved": "domain-approved",
    },
    "experts": {
        "expertScreen": "expert-screen",
        "expertLearnMore": "expert-learn-more",
        "expertFinish": "expert-finish",
        "expertApplyError": "expert-apply-error",
        "expertProfileUpdate": "expert-profile-update",
        "expertProfileDelete": "expert-profile-delete",
        "expertProfilePreview": "expert-profile-preview",
        "expertAddTopicStart": "expert-add-topic-start",
        "expertAddTopicEnd": "expert-add-topic-end",
        "expertProfileEditStart": "expert-profile-edit-start",
        "expertProfileEdit": "expert-profile-edit",
        "expertPinVideo": "expert-pin-video",
        "expertUnpinVideo": "expert-unpin-video",
        "pageClick": "experts-page-click",
        "filterApplied": "experts-filter-apply",
        "connect": "connect-with-experts",
    },
    "exportProcess": {
        "next": "export-process-next",
        "manageAccounts": "export-process-manage",
        "thumbnailUploadStart": "export-process-upload-start",
        "thumbnailUpload": "export-process-upload",
        "fullscreen": "export-process-full-screen",
        "undo": "export-process-undo",
        "redo": "export-process-redo",
        "publishClick": "export-process-publish",
        "export": "export-video",
        "exitStart": "export-exit-start",
        "exit": "export-exit",
    },
    "freeUsers": {
        "accessProFeatures": "access-pro-features",
        "accessProFeaturesClick": "access-pro-features-click"
    },
    "live": {
        "liveConnect": "live-connect",
        "liveConnectDismiss": "live-conncet-dismiss",
        "LiveConnectScreen": "live-connect-screen",
        "liveDestScreen": "live-destination-screen",
        "liveDestSelect": "live-destination-select",
        "liveEnd": "live-end",
        "liveGo": "live-go",
        "livePosScreen": "live-post-screen",
        "liveStart": "live-start"
    },
    "navigation": {
        "goToMyTeam": "my-team",
    },
    "pages": {
        "publish": "pages-publish",
        "create": "pages-created",
        "copyUrl": "pages-copy-url",
        "shareStart": "pages-share-start",
        "share": "pages-share",
        "preview": "pages-preview",
        "settingsSave": "pages-settings-save",
        "expertSet": "pages-expert-set",
        "templateLogoUpload": "pages-template-upload-logo",
        "templateBackgroundUploadStart": "pages-template-add-background-start",
        "templateBackgroundUploadEnd": "pages-template-add-background-end",
        "templateAddSocial": "pages-template-social-add",
        "templateFooterButtonMenuClick": "pages-template-footer-edit",
        "templateFooterButtonMenuChange": "pages-template-footer-change",
        "templateButtonMenuClick": "pages-template-button-edit",
        "templateButtonMenuChange": "pages-template-button-change",
        "editInteractions": "pages-edit-interaction",
    },
    "payment": {
        "paymentScreenClick": "payment-screen-click",
        "paymentScreen": "payment-screen",
        "checkoutStart": "checkout-start",
        "checkoutStartFailed": "checkout-start-failed",
        "checkoutCompleted": "checkout-completed",
        "checkoutDismissed": "checkout-dismissed"
    },
    "preferences": {
        "change": "preference-change",
    },
    "profile": {
        "buyPlan": "buy-plan",
        "detachDevice": "detach-device",
        "paymentComplete": "payment-complete",
        "paymentFailed": "payment-failed",
        "paymentStart": "payment-start",
        "planOverrideDialog": "payment-override-dialog",
        "saveDetails": "save-user-details",
        "subscriptionReplace": "subscription-replace",
        "unsubscribePlan": "unsubscribe-plan",
        "loginAccount": "login-account",
        "deleteAccount": "delete-account"
    },
    "pricing": {
        "deskPricingClick": "desk-pricing-click"
    },
    "script": {
        'aiScriptRewriteOutputClick': 'ai-script-rewrite-output-click',
        'aiScriptRewriteOutput': 'ai-script-rewrite-output',
        'aiScriptRewriteMenuClick': 'ai-script-rewrite-menu-click',
        'aiScriptRewriteMenu': 'ai-script-rewrite-menu',
        'aiScriptCheckerStart': 'ai-text-segmentation-start',
        'aiScriptCheckerOutput': 'ai-text-segmentation-output',
        'aiScriptCheckerGenerate': 'ai-text-segmentation-generate',
        'aiScriptCheckerGenerateOutput': 'ai-text-segmentation-generate-output',
        'aiScriptCheckerSelect': 'ai-text-segmentation-select',
        'aiScriptCheckerPartSelect': 'ai-text-segmentation-part-select',
        'aiScriptCheckerGeneratedClick': 'ai-text-segmentation-generated-click',
        'aiScriptCheckerFeedback': 'ai-text-segmentation-feedback',
        'aiScriptCheckerLabelsDialogInteract':'ai-text-segmentation-part-select',
        'aiScriptCheckerLabelClick':'ai-text-segmentation-label-click',
        'aiScriptCheckerSearchClick':'ai-text-segmentation-search-click',
        "addCaptions": "script-add-captions",
        "addToSharedBin": "script-add-to-shared-bin",
        "applyTheme": "script-apply-theme",
        "create": "add-new-story",
        "createPage": "script-create-page",
        "deskScriptTakeClick": "desk-script-take-click",
        "deskScriptTakeBoostAudio": "desk-script-take-boost-audio",
        "deskScriptTakeEyeFixStart": "eye-contact-start",
        "deskScriptTakeEyeFixScreen": "eye-contact-screen",
        "deskScriptTakeRename": "desk-script-take-rename",
        "deskDialogDismiss": "desk-dialog-dismiss",
        "deskDialogDownload": "desk-dialog-download",
        "deskDialogNew": "desk-dialog-new",
        "deskDialogUpload": "desk-dialog-upload",
        "teleprompterRecord": "record",
        "teleprompterRecordStart": "record-start",
        "teleprompterSettingsMicStart": "record-settings-microphone-start",
        "teleprompterSettingsMicDone": "record-settings-microphone",
        "teleprompterSettingsCameraStart": "record-settings-camera-start",
        "teleprompterSettingsCameraDone": "record-settings-camera",
        "teleprompterSettingsClose": "record-settings-close",
        "teleprompterSettingOpen": "record-settings",
        "teleprompterPermissionStart": "record-permission-start",
        "teleprompterPermissionSelect": "record-permission-select",
        "download": "script-download",
        "editSegmentationClick": "edit-segmentation-click",
        "editSegmentationSave": "edit-segmentation-save",
        "embed": "script-embed",
        "embedCaptions": "embed-captions",
        "export": "script-export",
        "openInComposer": "script-open-in-composer",
        "saveCaptions": "script-save-captions",
        "saveScriptText": "update-story",
        "sendToApp": "script-send-to-app",
        "addToCollection": "script-collection-add",
        "mp4Convert": "mp4-convert",
        "uploadClick": "script-upload",
        "uploadStart": "upload-take-start",
        "uploadEnd": "upload-take-end",
        "uploadFailed": "upload-take-failed",
        "scriptInteractive": "script-interactive-video",
        "scriptThumbnail": "script-thumbnail",
        "deskScriptTipDismiss": "desk-script-tip-dismiss",
        "deskScriptTipClick": "desk-script-tip-click",
        "deskScriptUndo": "desk-script-undo",
        "deskScriptRedo": "desk-script-redo",
        "deskScriptAiStart": 'desk-script-ai-start',
        "deskScriptAiOut": 'desk-script-ai-output',
        "deskScriptAiInput": 'desk-script-ai-input',
        "deskScriptAiDelete": 'desk-script-ai-delete',
        "deskScriptAiAdd": 'desk-script-ai-add',
        "deskScriptAiChange": 'desk-script-ai-change',
        "deskScriptWhiteboardStart": "desk-script-whiteboard-start",
        "deskScriptWhiteboardSearch": "desk-script-whiteboard-search",
        "deskScriptWhiteboardSave": "desk-script-whiteboard-save",
        "deskScriptWhiteboardNew": "desk-script-whiteboard-new",
        "deskScriptWhiteboardEdit": "desk-script-whiteboard-edit",
        "deskScriptWhiteboardDeleteStart": "desk-script-whiteboard-delete-start",
        "deskScriptWhiteboardDeleteCancel": "desk-script-whiteboard-delete-cancel",
        "deskScriptWhiteboardDelete": "desk-script-whiteboard-delete",
        "deskScriptWhiteboardAdd": "desk-script-whiteboard-add",
        "aiHelpClick": "desk-script-ai-help-click",
        "scriptSendStart": "script-send-start",
        "scriptSendClear": "script-send-clear",
        "scriptSendAll": "script-send-all",
        "scriptSend": "script-send",
        "scriptPostFeed": "script-post-feed",
        "scriptCopyThumbnail": "script-copy-thumbnail",
        "boardsPostFeed": "boards-post-feed"
    },
    "sequence": {
        "add": "sequence-add",
        "addCaptions": "sequence-add-captions",
        "create": "sequence-create",
        "download": "sequence-download",
        "edit": "sequence-edit",
        "new": "sequence-new",
        "openInComposer": "sequence-open-in-composer",
        "remove": "sequence-remove",
        "sequenceVideoMaker": "sequence-video-maker",
        "removeStart": "sequence-remove-start",
        "save": "sequence-save"
    },
    "social": {
        "changeScope": "social-change-preference",
        "postAction": "social-analytics-post-menu",
        "addStart": "social-add-start",
        "add": "social-account",
        "refresh": "social-refresh",
        "deleteStart": "social-delete-start",
        "delete": "social-delete",
        "postAnalyticsTimeChange": "social-analytics-time-change",
        "postClick": "social-analytics-post-click",
        "socialAnalyticsTutorial": "social-analytics-tutorial",
        "socialAnalyticsRefresh": "social-analytics-refresh-click",
        "socialAnalyticsError": "social-analytics-error",
        "socialAnalyticsFilter": "social-analytics-filter",
    },
    "trashbin": {
        "restore": "restore-file",
        "deleteError": "delete-error",
    },
    "user": {
        "activation": "activation",
        "changeMail": "change-email",
        "changeMailClicked": "change-email-clicked",
        "changeMailConfirm": "change-email-confirm",
        "forgotPassword": "forgot-password",
        "hireExpert": "hire-expert",
        "login": "login",
        "logout": "logout",
        "register": "register",
        "resendActivation": "resend-activation"
    },
    "videoPopup": {
        "continue": "pop-up-video-continue",
        "dismiss": "pop-up-video-dismiss",
        "screen": "pop-up-video-screen"
    },
    "videoPreview": {
        "pause": "video-preview-pause",
        "play": "play-take"
    },
    "webOnBoarding": {
        "conclusion": "onboarding-finish-screen",
        "contactInfo": "onboarding-social-details-screen",
        "dismiss": "dismiss-walkthrough",
        "features": "onboarding-feature-screen",
        "featuresSelect": "onboarding-feature-selected",
        "finish": "finish-walkthrough",
        "industry": "select-role-screen",
        "industrySelect": "role-selected",
        "social": "video-types-screen",
        "socialSelect": "video-types-select",
        "socialTypesSelected": "selected-items",
        "start": "start-walkthrough"
    },
    "weeklyDigestDialog": {
        "open": "weekly-digest-dialog",
        "dismiss": "weekly-digest-dialog-dismiss",
        "subscribe": "weekly-digest-dialog-subscribe"
    },
    "thumbnailEditor": {
        "boardsThumbnailUpload": 'boards-thumbnail-upload',
        "boardsThumbnailEditor": 'boards-thumbnail-editor',
        "thumbnailTemplateSelect": 'thumbnail-template-select',
        "thumbnailColorSelect": 'thumbnail-color-select',
        "thumbnailUploadImage": 'thumbnail-upload-image',
        "thumbnailEditPhoto": 'thumbnail-edit-photo',
        "thumbnailCropStart": 'thumbnail-crop-start',
        "thumbnailCropSave": 'thumbnail-crop-save',
        "thumbnailBackgroundRemove": 'thumbnail-background-remove-start',
        "thumbnailBackgroundSave": 'thumbnail-background-remove-save',
        "thumbnailLogoUpload": 'thumbnail-logo-upload',
        "thumbnailResetChanges": 'thumbnail-reset-changes',
        "thumbnailEditSelect": 'thumbnail-edit-select',
        "thumbnailCreate": 'thumbnail-create',
        "thumbnailPrevious": 'thumbnail-previous',
        "thumbnailExitStart": 'thumbnail-exit-start',
        "thumbnailExit": 'thumbnail-exit',
    },
    "tutorialButton": {
        "tutorialClick": 'tutorial-click'
    },
    "trim": {
        "wordtrim": 'wordtrim',
        "wordtrimCancel": 'wordtrim-cancel',
        "wordtrimSave": 'wordtrim-save',
        "wordtrimEnd": 'wordtrim-end'
    },
    "welcomeOnboarding": {
        "onboardingValueScreen": "onboarding-value-screen",
        "onboardingQuestionSelect": "onboarding-question-select",
        "onboardingQuestionScreen": "onboarding-question-screen",
        "onboardingScreenReturn": "onboarding-screen-return",
        "onboardingMobileFinishScreen": "onboarding-mobile-finish-screen",
        "onboardingMobileFinishSelect": "onboarding-mobile-finish-select",
        "onboardingFinish": "onboarding-finish"
    },
    "translateCaptions": {
        "makerCaptionsTranslate": "maker-captions-translate",
        "makerCaptionsCreate": "maker-captions-create",
        "makerCaptionsSelect": "maker-captions-select",
        "makerCaptionsPageClick": "maker-captions-page-click",
        "makerCaptionsPage": "maker-captions-page",
        "scriptCaptions": "script-captions"
    },
    "subtitleFix": {
        "aiAutoFixScreenClick": "ai-auto-fix-screen-click",
        "aiAutoFixScreen": "ai-auto-fix-screen",
        "aiAutoFixOutput": "ai-auto-fix-output",
        "aiAutoFixEditStart": "ai-auto-fix-edit-start",
        "aiAutoFixEdit": "ai-auto-fix-edit",
        "aiAutoFixChoose": "ai-auto-fix-choose"
    }
}