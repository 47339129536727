import { Component } from '@angular/core';
import { DialogComponent } from '../../../shared/components/dialog/dialog.component';

@Component({
    selector: 'activation-dialog',
    templateUrl: './activation-dialog.component.html',
    styleUrls: ['./activation-dialog.component.css'],
    standalone: true
})
export class ActivationDialogComponent extends DialogComponent {

}
