
export enum SidebarOptions {
    NONE = "",
    TWITTER = "twitter",
    PIXABAY = "pixabay",
    ASSETS = "assets",
    MY_UPLOADS = "my-uploads",
    VIDEO_ASSETS = "video-assets",
    MY_VIDEO_UPLOADS = "my-video-uploads",
    IMAGE_ASSETS = "image-assets",
    MY_IMAGE_UPLOADS = "my-image-uploads",
    QUOTE = "quote",
    ACTION = "action",
    CAPTIONS_EDITOR = "theme-editor-captions-editor",
    STYLE = "style",
    INTERACTIONS_FAVORITES = "interactions-favorites",
    POLL = "poll",
    WHITEBOARD = 'whiteboard',
    AI_REWRITE = 'ai-rewrite',
    AI_CHECKER = 'ai_checker',
    AI_CHECKER_V2 = 'ai_checker_V2',
    AI_TWIN = 'ai_twin',
    AUDIOGRAM = 'audiogram'
}

export interface SidebarOption {
    action: SidebarOptions;
    title: string;
    iconUrl: string;
    activeIconUrl: string;
    id?: number;
    style?: string;
}