import {Component, Inject, OnInit} from '@angular/core';
import {DialogData} from "../../models/dialog-data";
import {DialogComponent} from "../dialog/dialog.component";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {Story} from "../../models/story";
import {MenuOption} from "../../models/menu-option";
import {AnonymousUserService} from "../../services/anonymous-user.service";
import {Router} from "@angular/router";
import {NgCookieService} from "../../services/ng-cookie.service";
import {LocalTakeHelperService} from "../../services/local-take-helper.service";
import JSZip from 'jszip';
import {NotificationsService} from "../../services/notifications.service";
import {AbsoluteImageComponent} from "../absolute-image/absolute-image.component";
import {NgFor} from "@angular/common";
import {AdminRequestsService} from "../../services/admin-requests.service";
import {UserHelperService} from "../../services/user-helper.service";
import {DeskManagerHelperService} from "../../services/desk-manager-helper.service";

export class ExistingUserDialogData extends DialogData {
}

enum ButtonSetting {
    downloadScript = 'Download Script',
    downloadTakes = 'Download take recordings',
    goToLogin = 'Continue with  your account'
}

@Component({
    selector: 'app-existing-user-dialog',
    templateUrl: './existing-user-dialog.component.html',
    styleUrls: ['./existing-user-dialog.component.css'],
    standalone: true,
    imports: [AbsoluteImageComponent, NgFor]
})
export class ExistingUserDialogComponent extends DialogComponent implements OnInit {
    readonly bigvuLink = "support@bigvu.tv"

    constructor(@Inject(MAT_DIALOG_DATA) public data: ExistingUserDialogData,
                private anonymousUserService: AnonymousUserService,
                private cookieService: NgCookieService,
                private localTakeHelperService: LocalTakeHelperService,
                private notifier: NotificationsService,
                private adminRequestsService: AdminRequestsService,
                private deskManagerHelperService: DeskManagerHelperService,
                private router: Router) {
        super(data);
    }

    buttons: MenuOption[] = []

    ngOnInit(): void {
        this.buttons = [
            new MenuOption(
                ButtonSetting.downloadScript,
                ButtonSetting.downloadScript,
                'script'),
            new MenuOption(
                ButtonSetting.downloadTakes,
                ButtonSetting.downloadTakes,
                'takes'),
            new MenuOption(
                ButtonSetting.goToLogin,
                ButtonSetting.goToLogin,
                'goToLogin'),
        ]
    }

    onClick = async (setting: ButtonSetting) => {
        switch (setting) {
            case ButtonSetting.downloadScript:
                await this.downloadScript();
                break;
            case ButtonSetting.downloadTakes:
                await this.downloadTakeRecordings();
                break;
            case ButtonSetting.goToLogin:
                await this.continueWithOldAccount();
                break;
        }
    }


    private downloadScript = async () => {
        try {
            const options: any = await this.adminRequestsService.getStoryListWithScript(
                {
                    deskId: this.deskManagerHelperService.getSelectedWorkspaceId(),
                    type: 'bigvu#presenter',
                    withScript: true
                });

            if (options.result.length === 0) {
                this.notifier.info('No scripts available for download')
                return;
            }

            const zip = new JSZip();
            const folder = zip.folder('scripts'); // Optional: Create a folder inside the zip for videos

            for (let i = 0; i < options.result.length; i++) {
                const script = options.result[i]?.script || "";
                let filename = options.result[i]?.headline || `script_${i}`;
                filename = `${filename}.txt`; // Ensure proper extension

                // Create a Blob for the text file
                const scriptBlob = new Blob([script], {type: 'text/plain'});
                folder.file(filename, scriptBlob); // Add the Blob to the folder
            }

            const zipBlob = await zip.generateAsync({type: 'blob'});
            // Create a download link for the Blob object
            const downloadLink = document.createElement('a');
            downloadLink.href = URL.createObjectURL(zipBlob);
            downloadLink.download = 'scripts.zip';
            downloadLink.click();

        } catch (e) {
            console.error('Error downloading script :', e);
        }


    }

    private downloadTakeRecordings = async () => {
        try {

            const options: any = await this.adminRequestsService.getStoryListWithScript(
                {
                    deskId: this.deskManagerHelperService.getSelectedWorkspaceId(),
                    type: 'bigvu#presenter',
                });

            if (options.result.length === 0) {
                this.notifier.info('No videos available for download')
                return;
            }

            const videos = await this.localTakeHelperService.getAllVideos();
            const localTakes = await this.localTakeHelperService.getAllVideosFromStories() || [];
            const localTakeStoryIds = localTakes.map((t) => t.storyId);
            const videosPresentedInStories = videos.filter(v => localTakeStoryIds.includes(v.storyId))
            const videosRelatedToStory = videosPresentedInStories.filter(v => v.isAnonymousTake);

            if (videosRelatedToStory.length === 0) {
                this.notifier.info('No videos available for download')
                return;
            }

            const zip = new JSZip();
            const folder = zip.folder('videos'); // Optional: Create a folder inside the zip for videos

            for (let i = 0; i < videosRelatedToStory.length; i++) {
                const video = videosRelatedToStory[i];
                const filename = `video_${i}_${video.fileName}.webm`; // Adding an index to the filename
                const videoBlob = new Blob([video.file], {type: 'video/webm'}); // Create a Blob with the correct MIME type
                folder.file(filename, videoBlob); // Add the Blob to the folder
            }

            const zipBlob = await zip.generateAsync({type: 'blob'});

            // Create a download link for the Blob object
            const downloadLink = document.createElement('a');
            downloadLink.href = URL.createObjectURL(zipBlob);
            downloadLink.download = 'videos.zip';
            downloadLink.click();
        } catch (error) {
            console.error('Error downloading videos:', error);
        }
    }

    private continueWithOldAccount = async () => {
        try {
            await this.anonymousUserService.clearAll();
            this.cookieService.clearAll();
            this.router.navigate(['login']);
            this.submit();
        } catch (e) {
            console.error(e)
        }
    }


}
