<div class="container-exist">

    <div class="take-close-button"
         (click)="data.onClose()">
        <button aria-label="Close">
            <img src="/img/sidebar-close-icon.svg" alt="image">
        </button>
    </div>

    <section class="left">
        <app-absolute-image
            [src]="'/img/existing-account.webp'"
            [bottom]="0"
            [left]="5">
        </app-absolute-image>
    </section>

    <section class="right">
        <div class="title">
            You already have an account with BIGVU
        </div>

        <div class="main-text">
            Download your script and recordings before logging into your existing accounts.
            When signing-in with your current account, your <b>current take recordings will be lost.</b>
        </div>

        <button *ngFor="let btn of buttons" class="btn-container"
            (click)="onClick(btn.value)"
            [class]="btn.style">
            {{btn.name}}
        </button>

        <div class="auth-support-text" style="text-align: center">
           <span span="gray-t">contact</span> <a href="mailto:support@bigvu.tv?Subject=BIGVU desk feedback" target="_top"><b>{{bigvuLink}}</b></a>
        </div>
    </section>
</div>