import { Validators } from "@angular/forms";
import { MIN_FULLNAME_LENGTH, MIN_PASSWORD_LENGTH } from "../../auth/constants/numbers";
import { ActionButtonConfig } from "../../form-elements/models/action-button-config";
import { FormControlConfig } from "../../form-elements/models/form-control-config";
import { EMAIL_TEXT_INPUT, PASSWORD_REGEX_VALIDATOR, PASSWORD_TEXT_INPUT } from "../../form-elements/config/common-inputs-config";

export interface RegistrationForm {
    fullName: string;
    email: string;
    password: string;
}

export const formConfig: FormControlConfig[] = [
    
    new FormControlConfig(
        "fullName",
        "text",
        null,
        "Full Name",
        [
            Validators.required, 
            Validators.minLength(MIN_FULLNAME_LENGTH)
        ],
    ),

    EMAIL_TEXT_INPUT.clone(),

    PASSWORD_TEXT_INPUT.clone()
        .addValidator(Validators.minLength(MIN_PASSWORD_LENGTH))
        .addValidator(PASSWORD_REGEX_VALIDATOR)

];

export const formSubmitButtons: ActionButtonConfig = {
    text: 'Register',
    style: 'auth-cta-container auth-submit-button',
};

export const socialProviderActionButtons: ActionButtonConfig[] = [
    { id: 'google_plus', text: 'Continue with Google', style: 'google-auth-button', sideIconUrl: '/img/google-auth-icon.svg', },
    // { id: 'facebook', text: 'Continue with Facebook', style: 'facebook-auth-button', sideIconUrl: '/img/welcome/conclusion/facebook.svg', },
]