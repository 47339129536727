import {EventSender} from "../event-sender.interface";
import * as Sentry from "@sentry/angular";
import {event} from "jquery";

export class SentryEventSender implements EventSender {

    constructor() {

    }

    identify(userId: string): void {
        Sentry.setUser({id: userId});
    }

    init(userProperties: {}): void {
    }

    log(event: string, data?: any): void {
        Sentry.addBreadcrumb({
            type: 'user',
            category: 'log.event',
            message: event,
            timestamp: Date.now(),
            data: data
        });
    }

    logPageView(properties: {}): void {
    }

    logRevenue(plan: string, price: number): void {
    }

    clear(): void {
    }

    logSinglePurchases(purchaseQuantity: number): void {
    }

    updateUserProperties(userProperties: {}): void {
    }

}