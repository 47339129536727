import {Injectable} from '@angular/core';
import {AnonymousUserService} from "./anonymous-user.service";
import {PlanHelperService} from "./plan-helper.service";
import {UserHelperService} from "./user-helper.service";
import {DeskManagerHelperService} from "./desk-manager-helper.service";
import {LoginHelperService} from "./login-helper.service";
import {EventsService} from "./events.service";
import {OrganizationStateService} from "./organization-state.service";
import {WebsocketService} from "./websocket.service";
import {Router} from "@angular/router";
import {environment} from "../../environments/environment.dev";

@Injectable({
    providedIn: 'root'
})
export class AnonymousLoginService {

    constructor(private anonymousUserService: AnonymousUserService,
                private planHelperService: PlanHelperService,
                private userHelperService: UserHelperService,
                private deskManagerHelperService: DeskManagerHelperService,
                private loginHelperService: LoginHelperService,
                private eventsService: EventsService,
                private organizationStateService: OrganizationStateService,
                private websocketService: WebsocketService,
                private router: Router,
    ) {

    }


    loginWithDeviceId = async () => {
        const deviceId = await this.anonymousUserService.getDeviceIdOrGenerateNew();
        await this.anonymousUserService.setAnonymousUser();
        await this._login(deviceId)
    }


    private _login = async (deviceId: string) => {
        try {
            this.planHelperService.clear()
            this.userHelperService.clearUser();
            this.deskManagerHelperService.clear();
            await this.loginHelperService.loginWithDeviceId(deviceId);
            this.deskManagerHelperService.initializeWorkspace();
            await this.eventsService.init();
            await this.organizationStateService.init();
            this.websocketService.connect();
            this.router.navigate([environment.globals.anonymousFirstPage]);
        } catch (error) {
            throw error;
        }
    }


}
