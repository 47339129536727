<div class="circle"
     [style.opacity]="opacity"
     [style.background]="color"
     [style.width.px]="size"
     [style.height.px]="size"
     [style.top.px]="top !== undefined ? top: 'auto'"
     [style.bottom.px]="bottom !== undefined ? bottom: 'auto'"
     [style.left.px]="left !== undefined ? left: 'auto'"
     [style.right.px]="right !== undefined ? right: 'auto'"
    [style.z-index]="zIndex !== undefined ? zIndex: 0">
</div>
