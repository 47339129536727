import {EventSender} from "../event-sender.interface";
import {environment} from "../../../../environments/environment";
import EventNames from "../event-names";
import {event} from "jquery";

declare const fbq: any;

export class FacebookEventSender implements EventSender {

    eventNamesForMarketing = [
        EventNames.freeUsers.accessProFeatures,
        EventNames.webOnBoarding.start,
        EventNames.webOnBoarding.finish,
        EventNames.profile.buyPlan,
        EventNames.profile.paymentComplete,
        EventNames.profile.paymentStart,
        EventNames.user.register,
        EventNames.user.login,
        EventNames.social.add,
        EventNames.applyTheme.generate,
        EventNames.exportProcess.export,
        EventNames.script.teleprompterRecord,
        EventNames.script.create,
        EventNames.script.saveScriptText,
        EventNames.composer.reportCrash,
        EventNames.composer.sendCrashReport
    ];

    init(userProperties: {}): void {
    }

    identify(userId: string): void {
    }

    log(event: string, data?: any): void {
        if (environment.production && this.eventNamesForMarketing.includes(event)) {
            // facebook is editing the object, lets clone it
            let clonedData = data;
            try {
                if (data) {
                    clonedData = JSON.parse(JSON.stringify(data));
                    // email is not allowed to send to facebook
                    if ("email" in clonedData) {
                        delete clonedData.email
                    }
                }
            } catch (e) {
                // do nothing
            }

            //Facebook analytics
            fbq('trackCustom', `${event}`, clonedData)

            if (event === "register") {
                fbq('track', 'CompleteRegistration')
            }
        }
    }

    logRevenue(plan: string, price: number): void {
    }

    logPageView(properties: {} = {}): void {
    }

    clear(): void {
    }

    logSinglePurchases(purchaseQuantity: number): void {
    }

    updateUserProperties(userProperties: {}): void {
    }

}