import { FormControl, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { PASSWORD_PATTERN_REGEX, PASSWORD_VALIDATION_ERROR } from "../../auth/constants/strings";
import { FormControlConfig } from "../models/form-control-config";
import { RegexValidator } from "../models/regex-validator";

export const EMAIL_TEXT_INPUT = 
    new FormControlConfig(
        "email",
        "email",
        null,
        "Email",
        [
            Validators.required, 
            Validators.email
        ],
        undefined,
        false,
    );

export const PASSWORD_TEXT_INPUT = 
    new FormControlConfig(
        "password",
        "password",
        null,
        "Password",
        [Validators.required],
        undefined,
        false,
    );

export const PASSWORD_REGEX_VALIDATOR: ValidatorFn = 
    (control: FormControl): ValidationErrors | null => {    
        const regexValidator = new RegexValidator(PASSWORD_PATTERN_REGEX);
        return regexValidator.logic(control) ? null : { password: PASSWORD_VALIDATION_ERROR}
    }
