<div class="auth-screen-component">

    <div class="top-bar" *ngIf="showAbsoluteStaff">
        <div class="logo-container">
            <img src="/img/brand-logo.svg" alt="image">
        </div>
    </div>

    <!-- The screen content projection slot -->
    <div class="screen-content" [ngClass]="{'portable': !showAbsoluteStaff}">

        <div class="screen-title">{{ title }}</div>

        <ng-container
            [ngTemplateOutlet]="screenContentRef">
        </ng-container>
    </div>


    <app-absolute-circle *ngIf="showAbsoluteStaff" class="not-mobile" [size]="560" [left]="-160" [bottom]="-264" [color]="color"></app-absolute-circle>
    <app-absolute-circle *ngIf="showAbsoluteStaff" class="not-mobile" [size]="183" [left]="300" [top]="40" [color]="color"></app-absolute-circle>
    <app-absolute-circle *ngIf="showAbsoluteStaff" class="not-mobile" [size]="386" [right]="-62" [top]="40" [color]="color"></app-absolute-circle>
    <app-absolute-circle *ngIf="showAbsoluteStaff" class="not-mobile" [size]="165" [right]="20" [bottom]="40" [color]="color"></app-absolute-circle>
    <app-absolute-image *ngIf="showAbsoluteStaff" class="not-mobile" [src]="socialSpikeSrc"  [top]="10" [left]="0"></app-absolute-image>

</div>
