import { ElementRef, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorElementData } from '../models/error-element-data';

@Injectable({
    providedIn: 'root'
})
export class ErrorElementService {

    private renderer: Renderer2; 

    mainErrorElement: ElementRef;

    constructor(
        private rendererFactory: RendererFactory2, 
        private router: Router,
    ) { 
        this.renderer = this.rendererFactory.createRenderer(null, null);
    }

    appendError = (hostElement: ElementRef, elementsData: ErrorElementData[]): void => {
        if (this.mainErrorElement) {
            this.renderer.removeChild(hostElement.nativeElement || hostElement, this.mainErrorElement);
        }

        this.mainErrorElement = this.renderer.createElement('div');

        elementsData.forEach(data => {
            let element: ElementRef;

            if (data.text) {
                element = this.renderer.createText(data.text);
            } else if (data.link) {
                element = this.renderer.createElement('a');
                this.renderer.setAttribute(element, 'href', data.link.url);
                const textElement = this.renderer.createText(data.link.text);
                this.renderer.appendChild(element, textElement);
                this.renderer.listen(element, 'click', (event) => {
                    event.preventDefault();
                    this.router.navigate([data.link.url], { queryParams: data.link.queryParams });
                })
            } else if (data.lineBreak) {
                element = this.renderer.createElement('br');
            }
            this.renderer.appendChild(this.mainErrorElement, element)
        });

        this.renderer.appendChild(hostElement.nativeElement || hostElement, this.mainErrorElement);
    }
}
